import React from 'react';
import {
  useLoaderData,
  Form,
  redirect
} from "react-router-dom";
import {get, saveOrUpdate} from '../shared/util';

export async function loader({params: {locationId}}) {
  const location = await get(`/api/locations/${locationId}`);
  return { location };
}

export async function action({request, params: {locationId}}) {
  const formData = await request.formData();

  let location = Object.fromEntries(formData);
  if (locationId) {
    location = Object.assign({}, location, {id: locationId});
  }
  await saveOrUpdate(`/api/locations`, location);
  return redirect(`/`);
}

function Location() {
  const {location} = useLoaderData() || {location:{}};
  
  return (
    <div className="container">
      <div className="row">
      <div className="col-sm-4">
        <h4>
          Location
        </h4>
        <Form method="post">
          <div className="form-group mb-2">
            <label>Location Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="name"
              defaultValue={location.name || ''}
              autoFocus
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
          >
            Save
          </button>
        </Form>
      </div>
    </div>
    </div>
  );
}

export default Location;
