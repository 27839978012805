import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { Link, useLoaderData } from "react-router-dom";
import {LocationContext} from '../LocationContext';
import { get, deleteById, saveOrUpdate, updateArrayItem } from "../shared/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../DnDItemTypes.js";

export async function loader({params}) {
  const {locationId} = params;
  const lineItems = await get(`/api/locations/${locationId}/lineItems`);
  const locations = await get(`/api/locations`);
  return {lineItems, locations};
}

const CurrenceyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

function ManageEmployees() {
  const [location] = useContext(LocationContext);
  const {locations} = useLoaderData();
  const [lineItems, setLineItems] = useState(useLoaderData().lineItems);
  const deleteLineItemFromArray = (array, id, type) =>
    array.filter(
      ({ lineItemId, lineItemType }) =>
        id !== lineItemId || type !== lineItemType
    );
  const getNextOrder = () => {
    const lastOrder = lineItems.reduce((accumulator, current) => {
      if (current.order > accumulator) {
        return current.order;
      } else {
        return accumulator;
      }
    }, 0);
    return Math.ceil(lastOrder) + 1;
  };
  const handleClickRemoveEmployee =
    ({ name, id }) =>
    async (e) => {
      e.preventDefault();
      if (window.confirm(`Are you sure you want to remove ${name}?`)) {
        try {
          await deleteById(`/api/locations/${location.id}/employees`, id);
          toast.success("Employee removed");
          setLineItems(deleteLineItemFromArray(lineItems, id, "employee"));
        } catch (e) {
          toast.error("Failed to remove employee!");
        }
      }
    };
  const handleClickRemoveCategory =
    ({ title, id }) =>
    async (e) => {
      e.preventDefault();
      if (window.confirm(`Are you sure you want to remove ${title}?`)) {
        try {
          await deleteById(`/api/locations/${location.id}/categories`, id);
          toast.success("Cateory removed");
          setLineItems(deleteLineItemFromArray(lineItems, id, "category"));
        } catch (e) {
          toast.error("Failed to remove employee!");
        }
      }
    };
  const handleLocationChange = e => {
    const { value } = e.target;
    const location = locations.find(({id}) => id === parseInt(value));
    window.location.assign(`/locations/${location.id}/employees`);
  };

  return location && (
    <div className="container manage-employees">
      <div className="row">
        <div className="col-sm-12">
          <Link
            className="btn btn-primary btn-sm mb-1"
            to={`/locations/${location.id}/employees/create?order=${getNextOrder()}`}
          >
            <FontAwesomeIcon icon="plus" /> Employee
          </Link>
          &nbsp;
          <Link
            className="btn btn-primary btn-sm mb-1"
            to={`/locations/${location.id}/categories/create?order=${getNextOrder()}`}
          >
            <FontAwesomeIcon icon="plus" /> Category
          </Link>
          &nbsp;
        <select value={location.id} onChange={handleLocationChange}>
          {locations && locations.map(location => (
            <option key={location.id} value={location.id}>{location.name}</option>
          ))}
        </select>
        </div>
        <div className="col-sm-12">
          <table className="table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Wage ($/hour)</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {lineItems &&
                lineItems
                  .sort((x, y) => y.order - x.order)
                  .map((lineItem, index) => {
                    switch (lineItem.lineItemType) {
                      case "employee":
                        return (
                          <DraggableLineItem
                            employee={lineItem.employee}
                            handleClickRemoveEmployee={
                              handleClickRemoveEmployee
                            }
                            lineItem={lineItem}
                            lineItems={lineItems}
                            setLineItems={setLineItems}
                            index={index}
                            key={index}
                            LineItem={Employee}
                          />
                        );
                      case "category":
                        return (
                          <React.Fragment key={index}>
                            {index > 0 && (
                              <DraggableLineItem
                                LineItem={EmptyTableRow}
                                numberOfCells={3}
                                lineItem={lineItem}
                                lineItems={lineItems}
                                setLineItems={setLineItems}
                              />
                            )}
                            <DraggableLineItem
                              LineItem={Category}
                              category={lineItem.category}
                              handleClickRemoveCategory={
                                handleClickRemoveCategory
                              }
                              lineItem={lineItem}
                              lineItems={lineItems}
                              setLineItems={setLineItems}
                              index={index}
                            />
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export function DraggableLineItem(props) {
  const { LineItem, lineItems, lineItem, setLineItems, index } = props;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.EMPLOYEE,
      item: lineItem,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [lineItems, lineItem]
  );

  const [{ isOverItem }, drop] = useDrop(
    () => ({
      accept: ItemTypes.EMPLOYEE,
      drop: moveEmployee(lineItems, setLineItems, lineItem),
      collect: (monitor) => ({
        isOverItem: !!monitor.isOver(),
      }),
    }),
    [lineItems, lineItem]
  );

  const [{ isOverLast }, dropLast] = useDrop(
    () => ({
      accept: ItemTypes.EMPLOYEE,
      drop: moveEmployee(lineItems, setLineItems, lineItem, -1),
      collect: (monitor) => ({
        isOverLast: !!monitor.isOver(),
      }),
    }),
    [lineItems, lineItem]
  );
  function attachRef(el) {
    preview(el);
    drop(el);
  }
  const rowStyle = (isOver) => ({
    opacity: isDragging ? 0.5 : 1,
    borderWidth: isOver ? "3px 0 0 0" : "1px 0",
    borderStyle: isOver ? "dashed" : "solid",
    borderColor: isOver ? "var(--bs-secondary)" : "var(--bs-border-color)",
  });
  const noBorder = (isOver) => ({
    borderWidth: isOver ? "3px 0 0 0" : "0",
    borderStyle: isOver ? "dashed" : "none",
    borderColor: isOver ? "var(--bs-secondary)" : "white",
  });
  return (
    <>
      <tr ref={attachRef} style={rowStyle(isOverItem)}>
        <LineItem {...props} drag={drag} />
      </tr>
      {index === lineItems.length - 1 && (
        <tr
          ref={dropLast}
          style={{ ...rowStyle(isOverLast), ...noBorder(isOverLast) }}
        >
          <EmptyTableRow {...props} />
        </tr>
      )}
    </>
  );
}

function Employee({
  employee,
  handleClickRemoveEmployee,
  lineItem,
  lineItems,
  setLineItems,
  drag,
}) {
  const {locationId} = employee;
  return (
    <>
      <td>
        <button
          className="btn btn-secondary btn-sm"
          ref={drag}
          style={{ cursor: "move" }}
        >
          <FontAwesomeIcon icon="bars" />
        </button>
        &nbsp;{employee.name}
      </td>
      <td>{CurrenceyFormatter.format(employee.wage)}</td>
      <td>
        <Link
          className="btn btn-primary btn-sm"
          to={`/locations/${locationId}/employees/${employee.id}`}
        >
          <FontAwesomeIcon icon="pencil" />
          &nbsp; Edit
        </Link>
        &nbsp;
        <button
          className="btn btn-danger btn-sm"
          onClick={handleClickRemoveEmployee(employee)}
        >
          <FontAwesomeIcon icon="trash" />
          &nbsp; Remove
        </button>
      </td>
    </>
  );
}

export function EmptyTableRow({ numberOfCells }) {
  return [...Array(numberOfCells).keys()].map((key, index) => (
    <td style={{ border: "none" }} key={`emptyrow-${index}`}>
    </td>
  ));
}

function Category({
  category,
  lineItem,
  lineItems,
  setLineItems,
  index,
  handleClickRemoveCategory,
  drag,
}) {
  const {locationId} = lineItem;
  return (
    <>
      <th className="bg-secondary text-white">
        <button
          className="btn btn-light btn-sm"
          ref={drag}
          style={{ cursor: "move" }}
        >
          <FontAwesomeIcon icon="bars" />
        </button>
        &nbsp;{category.title}
      </th>
      <th className="bg-secondary text-white">
        <div style={{ height: "23px" }}>&nbsp;</div>
      </th>
      <th className="bg-secondary text-white">
        <Link
          className="btn btn-primary btn-sm"
          to={`/locations/${locationId}/categories/${category.id}`}
        >
          <FontAwesomeIcon icon="pencil" />
          &nbsp; Edit
        </Link>
        &nbsp;
        <button
          className="btn btn-danger btn-sm"
          onClick={handleClickRemoveCategory(category)}
        >
          <FontAwesomeIcon icon="trash" />
          &nbsp; Remove
        </button>
      </th>
    </>
  );
}

function moveEmployee(lineItems, setLineItems, targetLineItem, direction) {
  return async function (originLineItem) {
    const {locationId} = originLineItem;
    direction = direction || 1;
    if (originLineItem.id === targetLineItem.id) {
      return;
    }

    const sortedLineItems = lineItems.sort((x, y) => x.order - y.order);

    const targetLineItemIndex = sortedLineItems.findIndex(
      (i) => i.id === targetLineItem.id
    );
    let newOrder;

    if (targetLineItemIndex === 0) {
      if (direction === 1) {
        newOrder =
          (sortedLineItems[targetLineItemIndex].order +
            sortedLineItems[targetLineItemIndex + 1].order) /
          2;
      } else if (direction === -1) {
        newOrder = sortedLineItems[0].order - 1;
      }
    } else if (
      targetLineItemIndex > 0 &&
      targetLineItemIndex < sortedLineItems.length - 1
    ) {
      newOrder =
        (sortedLineItems[targetLineItemIndex].order +
          sortedLineItems[targetLineItemIndex + 1].order) /
        2;
    } else if (targetLineItemIndex === sortedLineItems.length - 1) {
      newOrder = sortedLineItems[targetLineItemIndex].order + 1;
    }
    if (newOrder !== undefined) {
      const updatedLineItem = await saveOrUpdate(
        `/api/locations/${locationId}/lineItems`,
           Object.assign({}, originLineItem, {
          order: newOrder,
        })
      );

      setLineItems(updateArrayItem(lineItems, updatedLineItem));
    }
  };
}

export default ManageEmployees;
