import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Shift from './Shift';
import {CurrenceyFormatter} from './Schedule';

function Employee({
  daysInWeekFromTuesday,
  schedule,
  navigate,
  findShiftForEmployee,
  calculateTotalCostPerEmployee,
  calculateTotalHours,
  lineItem,
  lineItems,
  setLineItems,
  reloadSchedule,
  drag,
}) {
  const { employee } = lineItem;

  return (
    <>
      <td className="first-column">
        <button
          className="btn btn-sm btn-secondary d-print-none"
          ref={drag}
          style={{ cursor: "move" }}
        >
          <FontAwesomeIcon icon="bars" />
        </button>
        &nbsp;{employee.name}
      </td>
      {daysInWeekFromTuesday.map((day) => (
        <Shift
          key={`${employee.id}-${day.format("YYYY-MM-DD")}`}
          shift={findShiftForEmployee(schedule, employee.id, day)}
          day={day}
          employee={employee}
          reloadSchedule={reloadSchedule}
        />
      ))}
      <td className="d-print-none">
        <div style={{ height: "23px" }}>{`${CurrenceyFormatter.format(
          calculateTotalCostPerEmployee(employee.id, schedule)
        )} (${calculateTotalHours(employee.id, schedule)}hrs)`}</div>
      </td>
    </>
  );
}

export default Employee;