import React from 'react';
/*=============================================
=            Arrays            =
=============================================*/

export const addArrayItem = (array, item) => [...array, item];

export const updateArrayItem = (array, updatedItem) =>
  array.map(item => (item.id === updatedItem.id ? updatedItem : item));

export const deleteArrayItemById = (array, itemId) =>
  array.filter(({ id }) => id !== itemId);

/*=====  End of Arrays  ======*/

/*=====================================
=            Http Requests            =
=====================================*/

export const validateResponse = async response => {
  if (!response.ok) {
    const errorMessage = await response.text();
    console.error(errorMessage);
    const error = new Error(errorMessage);
    error.status = response.status;
    throw error;
  }
};
export const get = async url => {
  const response = await fetch(url);
  await validateResponse(response);
  return await response.json();
};

export const saveOrUpdate = async (baseUrl, item) => {
  const response = await fetch(item.id ? `${baseUrl}/${item.id}` : baseUrl, {
    method: item.id ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
  await validateResponse(response);
  return await response.json();
};

export const deleteById = async (baseUrl, itemId) => {
  const response = await fetch(`${baseUrl}/${itemId}`, {
    method: 'DELETE'
  });
  await validateResponse(response);
};

/*=====  End of Http Requests  ======*/

/*==================================
=            Components            =
==================================*/

export const InlineFormItem = ({ label, children }) => (
  <>
    <label className="col-sm-5 col-form-label col-form-label-sm">{label}</label>
    <div className="col-sm-7 my-1">{children}</div>
  </>
);

/*=====  End of Components  ======*/
