import React, { useState, useEffect, useContext } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocationContext } from "../LocationContext";
import { get, saveOrUpdate } from "../shared/util";
import { DraggableLineItem, EmptyTableRow } from "../Employee/ManageEmployees";
import Employee from './Employee';

export async function loader({ params, request }) {
  const { locationId } = params;
  const lineItems = await get(`/api/locations/${locationId}/lineItems`);
  const sales = await get(`/api/locations/${locationId}/sales`);
  const schedule = await loadSchedule({ requestUrl: request.url, locationId });
  const locations = await get(`/api/locations`);
  const employees = lineItems.reduce((acc, lineItem) => {
    if (lineItem.lineItemType === "employee") {
      return [...acc, lineItem.employee];
    }
    return acc;
  }, []);
  return {
    lineItems,
    schedule,
    locations,
    sales,
    employees,
  };
}

export const CurrenceyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

async function loadSchedule({ requestUrl, locationId }) {
  const { startDate, endDate } = getStartAndEndDate(requestUrl);
  const schedule = await get(
    `/api/locations/${locationId}/schedule?startDate=${startDate.format(
      "YYYY-MM-DD"
    )}&endDate=${endDate.format("YYYY-MM-DD")}`
  );

  const rawSchedule = schedule.map((x) =>
    Object.assign({}, x, {
      dayOfWeek: getDayOfWeekFromDate(x.date),
    })
  );
  return mapSchedule(rawSchedule);
}

function mapSchedule(schedule) {
  const scheduleMapByEmployeeIdandDate = schedule.reduce(
    (accumulator, shift) => {
      if (!accumulator[shift.employeeId]) {
        accumulator[shift.employeeId] = {};
      }
      accumulator[shift.employeeId][shift.date] = shift;

      return accumulator;
    },
    {}
  );
  
  //Set cumulativeHours
  Object.keys(scheduleMapByEmployeeIdandDate).forEach((employeeId) => {
    setCumulativeHours(scheduleMapByEmployeeIdandDate[employeeId])
  });

  return scheduleMapByEmployeeIdandDate;
}

function setCumulativeHours(employeeShifts) {
const datesArray = [];
    Object.keys(employeeShifts).forEach((date, index) => {
        datesArray.push(date);
        const shift = employeeShifts[date];
        const shiftDuration = getTimeDifferenceInHours(
          shift.startTime,
          shift.endTime
        );
        if (index === 0) {
          employeeShifts[date].cumulativeHours =
            shiftDuration;
        } else if (index > 0) {
          employeeShifts[date].cumulativeHours =
            employeeShifts[datesArray[index - 1]]
              .cumulativeHours + shiftDuration;
        }
    });
}

function getTimeDifferenceInHours(startTime, endTime) {
  const start = moment(startTime, [moment.ISO_8601, "HH:mm:ss"]);
  const end = moment(endTime, [moment.ISO_8601, "HH:mm:ss"]);
  const duration = moment.duration(end.diff(start));
  return duration.asHours();
}

function getDayOfWeekFromDate(date) {
  return parseInt(moment.utc(date).format("d"));
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function getStartAndEndDate(url = window.location.href) {
  let startDate = getParameterByName("startDate", url);

  if (startDate) {
    startDate = moment(startDate);
  } else if (!startDate) {
    const daysSinceTuesday =
      moment().format("d") >= 2
        ? parseInt(moment().format("d")) - 2
        : parseInt(moment().format("d")) + 5;
    startDate = moment().subtract(daysSinceTuesday, "days");
  }

  const endDate = moment(startDate).add(6, "days");
  return { startDate, endDate };
}

function Schedule() {
  const [location] = useContext(LocationContext);
  const { sales, employees, locations } = useLoaderData();
  const [schedule, setSchedule] = useState(useLoaderData().schedule);
  const [lineItems, setLineItems] = useState(useLoaderData().lineItems);
  const navigate = useNavigate();

  const { startDate } = getStartAndEndDate();

  const daysInWeekFromTuesday = [...Array(7).keys()].map((day) =>
    moment(startDate).add(day, "days")
  );

  const getProjectedSalesFromTuesday = () =>
    daysInWeekFromTuesday.map((day) => {
      const salesDay = sales.find(
        (sale) => sale.date === moment(day).format("YYYY-MM-DD")
      );
      return (
        salesDay || {
          projectedSales: 0,
          date: moment(day).format("YYYY-MM-DD"),
        }
      );
    });

  const calculateTotalCostForDay = (date, schedule) => {
    return Object.keys(schedule).reduce((accumulator, employeeId) => {
      const shift = schedule[employeeId][date];

      if (!shift) {
        return accumulator;
      }
      const shiftDuration = getTimeDifferenceInHours(
        shift.startTime,
        shift.endTime
      );
      if (shift.cumulativeHours <= 40) {
        return accumulator + shiftDuration * shift.wage;
      } else if (shift.cumulativeHours - shiftDuration >= 40) {
        return accumulator + shiftDuration * shift.wage * 1.5;
      } else if (shift.cumulativeHours - shiftDuration < 40) {
        const overtimeHours = shift.cumulativeHours - 40;
        const regularHours = shiftDuration - overtimeHours;
        return accumulator + (overtimeHours * 1.5 + regularHours) * shift.wage;
      }
      console.warn("Missing case in calculateTotalCostForDay.");
      return null;
    }, 0);
  };

  const [projectedSalesFromTuesday, setProjectedSalesFromTuesday] = useState(
    getProjectedSalesFromTuesday()
  );
  const [totalProjectedSales, setTotalProjectedSales] = useState(0);

  const calculateLaborPercentagePerDay = () => {
    return projectedSalesFromTuesday.map((saleDay) => {
      const totalCost = calculateTotalCostForDay(saleDay.date, schedule);
      const projectedSalesForDay = saleDay.projectedSales;
      if (!projectedSalesForDay) {
        return 0;
      }
      return Math.round((totalCost / projectedSalesForDay) * 100);
    });
  };

  const [laborPercentagePerDay, setLaborPercentagePerDay] = useState(
    calculateLaborPercentagePerDay()
  );

  const [totalLaborPercentage, setTotalLaborPercentage] = useState(0);

  useEffect(() => {
    setLaborPercentagePerDay(calculateLaborPercentagePerDay());
    setTotalProjectedSales(
      projectedSalesFromTuesday.reduce(
        (acc, curr) => acc + curr.projectedSales,
        0
      )
    );
  }, [projectedSalesFromTuesday, schedule]);

  const calculateTotalCostPerEmployee = (employeeId, schedule) => {
    if (schedule[employeeId]) {
      let wage;
      const totalHours = Object.keys(schedule[employeeId]).reduce((accumulator, date) => {
        const shift = schedule[employeeId][date];
        if(!wage) {
          wage = shift.wage;
        }
        return (
          accumulator + getTimeDifferenceInHours(shift.startTime, shift.endTime)
        );
      }, 0);
      if (totalHours <= 40) {
        return totalHours * wage;
      } else if (totalHours > 40) {
        return (
          (totalHours - 40) * wage * 1.5 +
          40 * wage
        );
      }
    } else {
      return 0;
    }
  };

  const totalCost = employees.reduce((acc, employee) => {
    return acc + calculateTotalCostPerEmployee(employee.id, schedule);
  }, 0);

  useEffect(() => {
    setTotalLaborPercentage(
      Math.round((totalCost / totalProjectedSales) * 100)
    );
  }, [totalProjectedSales, totalCost]);

  const findShiftForEmployee = (schedule, employeeId, day) => {
    if (schedule[employeeId]) {
      const shift = schedule[employeeId][day.format("YYYY-MM-DD")];
      return shift;
    }
    return null;
  };

  const calculateTotalHours = (employeeId, schedule) => {
    if (schedule[employeeId]) {
      return Object.keys(schedule[employeeId]).reduce((accumulator, date) => {
        const shift = schedule[employeeId][date];
        return (
          accumulator + getTimeDifferenceInHours(shift.startTime, shift.endTime)
        );
      }, 0);
    } else {
      return 0;
    }
  };

  const updateProjectedSales = (saleDay, index) => async (e) => {
    const sales = {
      id: saleDay.id,
      date: moment(saleDay.date).format("YYYY-MM-DD"),
      projectedSales: e.target.value,
    };
    const result = await saveOrUpdate(
      `/api/locations/${location.id}/sales`,
      sales
    );

    setProjectedSalesFromTuesday(
      Object.assign([], projectedSalesFromTuesday, { [index]: result })
    );
  };

  const determinePreviousStartDate = () => {
    return moment(startDate).subtract(7, "days").format("YYYY-MM-DD");
  };

  const determineNextStartDate = () => {
    return moment(startDate).add(7, "days").format("YYYY-MM-DD");
  };

  const copyToNextWeek = async (e) => {
    await saveOrUpdate(
      `/api/locations/${
        location.id
      }/schedule/copyScheduleToNextWeek?startDate=${startDate.format(
        "YYYY-MM-DD"
      )}`,
      {}
    );
    alert(
      'This weeks schedule has been copied to next week.  Click "Next Week" to see updates.'
    );
  };

  const reloadSchedule = async () => {
    const schedule = await loadSchedule({ locationId: location.id });
    setSchedule(schedule);
  };
  const handleLocationChange = (e) => {
    const { value } = e.target;
    const location = locations.find(({ id }) => id === parseInt(value));
    window.location.assign(`/locations/${location.id}/schedule`);
  };

  return (
      <div className="row mx-1 schedule">
        <div className="col-sm-10 d-print-none mb-3">
          <a
            className="btn btn-secondary"
            href={`/locations/${
              location.id
            }/schedule?startDate=${determinePreviousStartDate()}`}
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp; Previous Week
          </a>
          &nbsp;
          <a
            className="btn btn-secondary"
            href={`/locations/${
              location.id
            }/schedule?startDate=${determineNextStartDate()}`}
          >
            Next Week &nbsp;
            <FontAwesomeIcon icon="arrow-right" />
          </a>
          &nbsp;
          <button className="btn btn-success" onClick={copyToNextWeek}>
            <FontAwesomeIcon icon="copy" />
            &nbsp; Copy to Next Week
          </button>
          &nbsp;
          <select value={location.id} onChange={handleLocationChange}>
            {locations &&
              locations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
          </select>
        </div>
        <div className="col-sm-2 d-print-none mb-3">
          <button
            className="btn btn-primary float-right"
            onClick={window.print}
          >
            <FontAwesomeIcon icon="print" />
            &nbsp; Print
          </button>
        </div>
        <div className="col-sm-12">
          <table className="table table-bordered table-sm">
            <thead>
              <tr>
                <th className="first-column">
                  <div style={{ height: "23px" }}>&nbsp;</div>
                </th>
                <th scope="col">T ({moment(startDate).format("M/D")})</th>
                <th scope="col">
                  W ({moment(startDate).add(1, "days").format("M/D")})
                </th>
                <th scope="col">
                  T ({moment(startDate).add(2, "days").format("M/D")})
                </th>
                <th scope="col">
                  F ({moment(startDate).add(3, "days").format("M/D")})
                </th>
                <th scope="col">
                  S ({moment(startDate).add(4, "days").format("M/D")})
                </th>
                <th scope="col">
                  S ({moment(startDate).add(5, "days").format("M/D")})
                </th>
                <th scope="col">
                  M ({moment(startDate).add(6, "days").format("M/D")})
                </th>
                <th scope="col" className="d-print-none">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="d-print-none">
                <th className="first-column">
                  <div style={{ height: "26px" }}>Projected Sales</div>
                </th>
                {projectedSalesFromTuesday.map((saleDay, index) => (
                  <td key={saleDay.id}>
                    $
                    <input
                      type="number"
                      defaultValue={saleDay.projectedSales}
                      onChange={updateProjectedSales(saleDay, index)}
                      onFocus={(e) => e.target.select()}
                      style={{ width: "80px" }}
                    />
                  </td>
                ))}
                <td>
                  <div style={{ height: "26px" }}>{`${CurrenceyFormatter.format(
                    totalProjectedSales
                  )}`}</div>
                </td>
              </tr>
              <>
                {lineItems
                    .sort((x, y) => y.order - x.order)
                    .map((lineItem, index) => {
                      switch (lineItem.lineItemType) {
                        case "employee":
                          return (
                            <DraggableLineItem
                              daysInWeekFromTuesday={daysInWeekFromTuesday}
                              schedule={schedule}
                              navigate={navigate}
                              findShiftForEmployee={findShiftForEmployee}
                              calculateTotalCostPerEmployee={
                                calculateTotalCostPerEmployee
                              }
                              calculateTotalHours={calculateTotalHours}
                              lineItem={lineItem}
                              lineItems={lineItems}
                              setLineItems={setLineItems}
                              key={`employee-${index}`}
                              LineItem={Employee}
                              reloadSchedule={reloadSchedule}
                              index={index}
                            />
                          );
                        case "category":
                          return (
                            <React.Fragment key={`category-${index}`}>
                              {index > 0 && (
                                <DraggableLineItem
                                  LineItem={EmptyTableRow}
                                  numberOfCells={9}
                                  lineItem={lineItem}
                                  lineItems={lineItems}
                                  setLineItems={setLineItems}
                                />
                              )}
                              <DraggableLineItem
                                category={lineItem.category}
                                index={index}
                                lineItem={lineItem}
                                lineItems={lineItems}
                                setLineItems={setLineItems}
                                LineItem={Category}
                              />
                            </React.Fragment>
                          );
                        default:
                          return null;
                      }
                    })}
              </>

              <tr className="d-print-none">
                <th className="bg-secondary text-white first-column">
                  <div style={{ height: "23px" }}>Percentages</div>
                </th>
                {daysInWeekFromTuesday.map((day, index) => (
                  <th
                    className="bg-secondary text-white"
                    key={`percentages-${index}`}
                  >
                    <div style={{ height: "23px" }}>&nbsp;</div>
                  </th>
                ))}
                <th className="bg-secondary text-white">
                  <div style={{ height: "23px" }}>&nbsp;</div>
                </th>
              </tr>
              <tr className="d-print-none">
                <th className="first-column">Labor Cost</th>
                {schedule && daysInWeekFromTuesday.map((day) => (
                  <td key={moment(day).format("d")}>
                    {`${CurrenceyFormatter.format(
                      calculateTotalCostForDay(day.format("YYYY-MM-DD"), schedule)
                    )}`}
                  </td>
                ))}
                <td>{`${CurrenceyFormatter.format(totalCost)}`}</td>
              </tr>
              <tr className="d-print-none">
                <th className="first-column">Labor Percentage</th>
                {laborPercentagePerDay &&
                  laborPercentagePerDay.map((laborPercentage, index) => (
                    <td key={`laborperc-${index}`}>{`${laborPercentage}%`}</td>
                  ))}
                <td>{`${totalLaborPercentage}%`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
}

function Category({
  index,
  category,
  lineItems,
  lineItem,
  setLineItems,
  drag,
}) {
  return (
    <>
      <th className="bg-secondary text-white first-column">
        <button
          className="btn btn-sm btn-light d-print-none"
          ref={drag}
          style={{ cursor: "move" }}
        >
          <FontAwesomeIcon icon="bars" />
        </button>
        &nbsp;{category.title}
      </th>
      <th className="bg-secondary text-white">
        <div style={{ height: "23px" }}>&nbsp;</div>
      </th>
      <th className="bg-secondary text-white">
        <div style={{ height: "23px" }}>&nbsp;</div>
      </th>
      <th className="bg-secondary text-white">
        <div style={{ height: "23px" }}>&nbsp;</div>
      </th>
      <th className="bg-secondary text-white">
        <div style={{ height: "23px" }}>&nbsp;</div>
      </th>
      <th className="bg-secondary text-white">
        <div style={{ height: "23px" }}>&nbsp;</div>
      </th>
      <th className="bg-secondary text-white">
        <div style={{ height: "23px" }}>&nbsp;</div>
      </th>
      <th className="bg-secondary text-white">
        <div style={{ height: "23px" }}>&nbsp;</div>
      </th>
      <th className="bg-secondary text-white d-print-none">
        <div style={{ height: "23px" }}>&nbsp;</div>
      </th>
    </>
  );
}




export default Schedule;
