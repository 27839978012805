import {
  faArrowUp,
  faArrowDown,
  faPlus,
  faPencil,
  faTrash,
  faArrowLeft,
  faArrowRight,
  faCopy,
  faPrint,
  faBars,
  faUsers,
  faCalendarDays
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

/* 
  Preload icons to avoid reimporting them everywhere in the app
  see - https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
*/
export const loadIcons = () => {
  library.add(
    faArrowUp,
    faArrowDown,
    faPlus,
    faPencil,
    faTrash,
    faArrowLeft,
    faArrowRight,
    faCopy,
    faPrint,
    faBars,
    faUsers,
    faCalendarDays
  );
};
