import React from 'react';
import {
  useLoaderData,
  Form,
  redirect,
  useSearchParams
} from "react-router-dom";
import {get, saveOrUpdate} from '../shared/util';

export async function loader({ params:{employeeId, locationId }}) {
  const employee = await get(`/api/locations/${locationId}/employees/${employeeId}`);
  return { employee };
}

export async function action({request, params: {employeeId, locationId}}) {
  const formData = await request.formData();

  let employee = Object.fromEntries(formData);
  employee = Object.assign({}, employee, {locationId});
  if (employeeId) {
    employee = Object.assign({}, employee, {id: employeeId});
  }
  await saveOrUpdate(`/api/locations/${locationId}/employees/`, employee);
  return redirect(`/locations/${locationId}/employees`);
}

function Employee() {
  const {employee} = useLoaderData() || {employee:{}};
  const [searchParams] = useSearchParams();
  const order = searchParams.get("order");

  return (
    <div className="container"><div className="row">
      <div className="col-sm-4">
        <h4>
          Employee
        </h4>
        <Form method="post">
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="name"
              defaultValue={employee.name || ''}
              autoFocus
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="wage">Wage</label>
            <input
              type="number"
              className="form-control"
              id="wage"
              name="wage"
              placeholder="Wage"
              step="0.01"
              min="0"
              defaultValue={employee.wage || 0}
            />
          </div>
          <input
              type="hidden"
              className="form-control"
              id="order"
              name="order"
              defaultValue={order || 0}
            />
          <button
            type="submit"
            className="btn btn-primary"
          >
            Save
          </button>
        </Form>
      </div>
    </div>
    </div>
  );
}

export default Employee;
