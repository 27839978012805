import React, { useState, useEffect, useContext } from 'react';
import { Path } from 'path-parser'; 
import { LocationContext } from "./LocationContext";

const validateResponse = async response => {
  if (!response.ok) {
    const errorMessage = await response.text();
    console.error(errorMessage);
    const error = new Error(errorMessage);
    error.status = response.status;
    throw error;
  }
};

const get = async url => {
  const response = await fetch(url);
  await validateResponse(response);
  return await response.json();
};

const UserContext = React.createContext([{}, () => {}]);

const UserContextProvider = props => {

  const [user, setUser] = useState();
  const [,setLocation] = useContext(LocationContext);

  useEffect(() => {
    const locationPath = new Path('/locations/:locationId');
    const pathObject = locationPath.partialTest(window.location.pathname);

    get('/api/login/current')
      .then(user => {
        setUser(user);
        if (user.locations.length === 0 && window.location.pathname !== '/welcome') {
          window.location.assign('/welcome');
        } else if (pathObject && pathObject.locationId) {
          setLocation(user.locations.find(location => location.id === parseInt(pathObject.locationId)));
        } else {
          setLocation(user.locations[0])
        }
      })
      .catch(e => {
        if (
          window.location.pathname !== '/login' &&
          window.location.pathname !== '/register' &&
          (e.status === 401 || e.status === 403)
        ) {
          window.location.assign('/login');
        }
      });
  }, []);
  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
