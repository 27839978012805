import {useState, useContext} from 'react';
import {
  useNavigate
} from "react-router-dom";
import {saveOrUpdate} from '../shared/util';
import { LocationContext } from "../LocationContext";

function Welcome() {
	const navigate = useNavigate();
	const [locationName, setLocationName] = useState('');
	const [, setLocation] = useContext(LocationContext);
	const handleSaveLocation = async e => {
		e.preventDefault();
		const location = await saveOrUpdate(`/api/locations`, {name: locationName});
		setLocation(location);
		navigate('/locations');
	}

	const handleChangeLocationName = e => {
		setLocationName(e.target.value);
	}	

	return (
		<div className="container">
      <div className="row">
      <div className="col-sm-4">
      <h2>Welcome to EZShiftz.</h2>  
      <h6>Please enter a location name to begin.</h6>
      <form onSubmit={handleSaveLocation}>
          <div className="form-group mb-2">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Location Name"
              value={locationName}
              onChange={handleChangeLocationName}
              autoFocus
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
          >
            Save
          </button>
        </form>
      </div>

      </div>
      </div>
	)
}

export default Welcome;