import React from 'react';
import {
  useLoaderData,
  Form,
  redirect,
  useSearchParams
} from "react-router-dom";
import {get, saveOrUpdate} from '../shared/util';

export async function loader({ params:{categoryId, locationId }}) {
  const category = await get(`/api/locations/${locationId}/categories/${categoryId}`);
  return { category };
}

export async function action({request, params:{categoryId, locationId } }) {
  const formData = await request.formData();

  let category = Object.fromEntries(formData);
  category = Object.assign({}, category, {locationId});
  if (categoryId) {
    category = Object.assign({}, category, {id: categoryId});
  }
  await saveOrUpdate(`/api/locations/${locationId}/categories/`, category);
  return redirect(`/locations/${locationId}/employees`);
}

function Category() {
  const {category} = useLoaderData() || {category:{}};
  const [searchParams] = useSearchParams();
  const order = searchParams.get("order");
  return (
    <div className="container"><div className="row">
      <div className="col-sm-4">
        <h4>
          Category
        </h4>
        <Form method="post">
          <div className="form-group mb-2">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              placeholder="title"
              defaultValue={category.title || ''}
              autoFocus
            />
          </div>
          <input
              type="hidden"
              className="form-control"
              id="order"
              name="order"
              defaultValue={order || 0}
            />
          <button
            type="submit"
            className="btn btn-primary"
          >
            Save
          </button>
        </Form>
      </div>
    </div>
    </div>
  );
}

export default Category;
