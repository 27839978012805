import React, { useContext } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { LocationContext } from "../LocationContext";
import { get } from "../shared/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export async function loader() {
  const locations = await get(`/api/locations`);
  return { locations };
}

function Locations() {
  const { locations } = useLoaderData();
  const navigate = useNavigate();
  const [, setLocation] = useContext(LocationContext)
  const navigateToEmployees = (location) => () => {
    setLocation(location);
    navigate(`/locations/${location.id}/employees`);
  }
  const navigateToSchedule = (location) => () => {
    setLocation(location);
    navigate(`/locations/${location.id}/schedule`);
  }
  return  (
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <Link
              className="btn btn-primary btn-sm mb-1"
              to={`/locations/create`}
            >
              <FontAwesomeIcon icon="plus" /> Location
            </Link>
          </div>
          <div className="col-sm-12">
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <th scope="col">Location Name</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {locations.map((location) => (
                  <tr key={location.id}>
                    <td>{location.name}</td>
                    <td>
                      <Link
                        className="btn btn-primary btn-sm"
                        to={`/locations/${location.id}`}
                      >
                        <FontAwesomeIcon icon="pencil" />
                        &nbsp; Edit
                      </Link>
        &nbsp;
        <button
          className="btn btn-success btn-sm"
          onClick={navigateToEmployees(location)}
        >
          <FontAwesomeIcon icon="users" />
          &nbsp; Employess &nbsp;
          <FontAwesomeIcon icon="arrow-right" />
        </button>
        &nbsp;
        <button
          className="btn btn-success btn-sm"
          onClick={navigateToSchedule(location)}
        >
          <FontAwesomeIcon icon="calendar-days" />
          &nbsp; Schedule &nbsp;
          <FontAwesomeIcon icon="arrow-right" />
        </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
}

export default Locations;
