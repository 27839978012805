import React, { useState } from 'react';


const LocationContext = React.createContext([{}, () => {}]);

const LocationContextProvider = props => {

  const [location, setLocation] = useState();

  return (
    <LocationContext.Provider value={[location, setLocation]}>
      {props.children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationContextProvider };
