import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function Register() {
  const [registration, setRegistration] = useState({
    username: '',
    password: '',
    confirmPassword: ''
  });

  const handleSubmitRegistration = async e => {
    e.preventDefault();
    if (registration.password !== registration.confirmPassword) {
      toast.error(`Passwords do not match.`, {
        autoClose: 5000
      });

    }
    try {
      const registerResponse = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...registration })
      });
      if (!registerResponse.ok) {
        const error = await registerResponse.text();
        throw new Error(error);
      }

      const loginResponse = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...registration })
      });
      if (!loginResponse.ok) {
        const error = await loginResponse.text();
        throw new Error(error);
      }
      window.location.assign('/welcome');
    } catch (e) {
      toast.error(`Failed to register user.  ${e.message}`, {
        autoClose: 5000
      });
    }
  };

  return (
    <div className="container">
    <div className="row">
      <div className="col-sm-4">
        <h4>
          Register or <Link to="/login">Login</Link>
        </h4>
        <form onSubmit={handleSubmitRegistration}>
          <div className="form-group">
            <label htmlFor="username">Email Address</label>
            <input
              type="email"
              className="form-control"
              id="username"
              placeholder="Username"
              required
              value={registration.username}
              onChange={e =>
                setRegistration({ ...registration, username: e.target.value })
              }
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              required
              minLength="8"
              value={registration.password}
              onChange={e =>
                setRegistration({ ...registration, password: e.target.value })
              }
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="password">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              id="confirm-password"
              placeholder="Password"
              required
              minLength="8"
              value={registration.confirmPassword}
              onChange={e =>
                setRegistration({ ...registration, confirmPassword: e.target.value })
              }
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary"
          >
            Register
          </button>
        </form>
      </div>
    </div>
    </div>
  );
}

export default Register;
