import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function Login() {
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });

  const handleSubmitLogin = async e => {
    e.preventDefault();
    try {
      const loginResponse = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      });
      if (!loginResponse.ok) {
        const error = await loginResponse.text();
        throw new Error(error);
      }
      window.location.assign('/');
    } catch (e) {
      console.log({ e });
      toast.error(`Login failed. ${e.message}`, { autoClose: 5000 });
    }
  };

  return (
    <div className="container"><div className="row">
      <div className="col-sm-4">
        <h4>
          Login or <Link to="/register">Register</Link>
        </h4>
        <form>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="Username"
              value={credentials.username}
              onChange={e =>
                setCredentials({ ...credentials, username: e.target.value })
              }
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              value={credentials.password}
              onChange={e =>
                setCredentials({ ...credentials, password: e.target.value })
              }
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmitLogin}
          >
            Login
          </button>
        </form>
      </div>
    </div>
    </div>
  );
}

export default Login;
