import React from 'react';
import ReactDOM from 'react-dom/client';
import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch' // or any other pipeline

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContextProvider } from './UserContext';
import { LocationContextProvider } from './LocationContext';
import { loadIcons } from './icon-loader';


import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

loadIcons();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocationContextProvider>
    <UserContextProvider>
    <DndProvider options={HTML5toTouch}>
      <App/>
      </DndProvider>
    </UserContextProvider>
    </LocationContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
