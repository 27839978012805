import { createBrowserRouter, RouterProvider, useRouteError, Outlet, Link} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { UserContext } from "./UserContext";
import { LocationContext } from "./LocationContext";
import { useContext } from "react";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import ManageEmployees, {
  loader as employeesLoader,
} from "./Employee/ManageEmployees";
import Employee, {
  action as employeeAction,
  loader as employeeLoader,
} from "./Employee/Employee";
import Category, {
  action as categoryAction,
  loader as categoryLoader,
} from "./Category/Category";
import Locations, {
  loader as locationsLoader,
} from "./Location/Locations";
import Location, {
  loader as locationLoader,
  action as locationAction
} from "./Location/Location";
import Schedule, {loader as scheduleLoader} from "./Schedule/Schedule";
import Welcome from "./Auth/Welcome";

const router = createBrowserRouter([{
  element: <Root />,
  errorElement: <ErrorBoundary/>,
  children: [{
    path: "/",
    element: <Locations />,
    loader: locationsLoader,
  },{
    path: "/welcome",
    element: <Welcome />
  },{
    path: "/locations",
    element: <Locations />,
    loader: locationsLoader,
  },{
    path: "/locations/create",
    element: <Location />,
    action:locationAction
  },{
    path: "/locations/:locationId",
    element: <Location />,
    loader: locationLoader,
    action:locationAction
  },{
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/locations/:locationId/employees",
    element: <ManageEmployees />,
    loader: employeesLoader,
  },
  {
    path: "/locations/:locationId/employees/:employeeId",
    element: <Employee />,
    loader: employeeLoader,
    action: employeeAction,
  },
  {
    path: "/locations/:locationId/employees/create",
    element: <Employee />,
    action: employeeAction,
  },
  {
    path: "/locations/:locationId/categories/create",
    element: <Category />,
    action: categoryAction,
  },
  {
    path: "/locations/:locationId/categories/:categoryId",
    element: <Category />,
    action: categoryAction,
    loader: categoryLoader,
  },
   {
    path: "/locations/:locationId/schedule",
    element: <Schedule />,
    loader: scheduleLoader,
  }]}
]);

function ErrorBoundary() {
  const {error} = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (<div className="container">
          <h2>Something went wrong.</h2>
          <details>
            {error.message}
          </details>
        </div>);
}

function Root() {
  const [user, setUser] = useContext(UserContext);
  const [location] = useContext(LocationContext);

  const handleClickLogout = async () => {
    await fetch("/api/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    setUser(null);
    window.location.assign("/login");
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light px-3 mb-3 d-print-none">
        <a className="navbar-brand" href="/">
          EZShiftz
        </a>
        {user && (
          <>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                  <Link className="nav-link" to={`/locations`}>
                    Locations
                  </Link>
                </li>
                {location && (<li className="nav-item">
                  <Link className="nav-link" to={`/locations/${location.id}/employees`}>
                    Employees
                  </Link>
                </li>)}
                {location && (<li className="nav-item">
                  <Link className="nav-link" to={`/locations/${location.id}/schedule`}>
                    Schedule
                  </Link>
                </li>)}
              </ul>
            </div>

            <Dropdown user={user} handleClickLogout={handleClickLogout} />
          </>
        )}
      </nav>
      <Outlet />
    </div>
  )
}

function App() {
  

  return (
    <div>
      <RouterProvider router={router} />
      <ToastContainer hideProgressBar={true} autoClose={1000} />
    </div>
  );
}

function Dropdown({ user, handleClickLogout }) {
  return (
    <div className="dropdown" style={{ marginRight: '100px' }}>
      <button
        className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
      >
        {user.username}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button className="dropdown-item" onClick={handleClickLogout}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default App;
