import React, { useState } from "react";
import moment from "moment";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../DnDItemTypes.js";
import { toast } from "react-toastify";
import { saveOrUpdate, deleteById } from "../shared/util";


function Shift({ employee, day, shift, reloadSchedule }) {
	const [isEditing, setIsEditing] = useState();
	const [hasError, setHasError] = useState(false);
	const [shiftTimeRange, setShiftTimeRange] = useState();
	
	const formattedShiftTime = shift? formatShiftTime(shift) : "";

	const editShift = () => {
		if(!isEditing) {
			setIsEditing(true);
			setShiftTimeRange(formattedShiftTime);
		}
	};

  const saveShift = async (day, shiftTimeRange, shift) => {
    const date = day.format("YYYY-MM-DD");
    if (shiftTimeRange === "") {
      if (shift?.id) {
        await deleteById(`/api/employees/${employee.id}/shifts`, shift.id);
        return null;
      }
      return null;
    }
    const { startTime, endTime } = parseTimeRange(shiftTimeRange);
      const updatedShift = await saveOrUpdate(
      `/api/employees/${employee.id}/shifts/`,
      Object.assign({}, shift, { startTime, endTime, date })
    );
    return updatedShift;
  };

	const saveShiftWithValidation = async (day, shiftTimeRange, shift) => {
		try {
			await saveShift(day, shiftTimeRange, shift);
			await reloadSchedule();
			setHasError(false);
			setIsEditing(false);
		} catch (e) {
			setHasError(true);
			toast.error(e.message);
		}
	};

	const handleOnBlur = async () => {
		await saveShiftWithValidation(day, shiftTimeRange, shift);
	};
	const handleKeyDown = async (e) => {
		if (e.key === "Enter") {
			await saveShiftWithValidation(day, shiftTimeRange, shift);
		}
		if (e.key === "Escape") {
			setIsEditing(false);
			setHasError(false);
		}
	};
	const handleChangeShift = (e) => {
		setShiftTimeRange(e.target.value);
		if (hasError && isTimeRangeValid(e.target.value)) {
			setHasError(false);
		}
	};

	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: ItemTypes.SHIFT,
			item: { formattedShiftTime },
			collect: (monitor) => ({
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[formattedShiftTime]
	);

	const handleDropShift =
		(day, shift) =>
		async ({ formattedShiftTime }) => {
			await saveShiftWithValidation(day, formattedShiftTime, shift);
		};

	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: ItemTypes.SHIFT,
			drop: handleDropShift(day, shift),
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
		}),
		[day, shift]
	);

	function attachRef(el) {
		drag(el);
		drop(el);
	}

	const tdStyle = () => {
		let tdStyle = {};
		if (isDragging) {
			tdStyle = {
				borderWidth: "3px",
				borderStyle: "solid",
				borderColor: "var(--bs-secondary)",
				padding: "1px",
			};
		} else if (isOver) {
			tdStyle = {
				borderWidth: "3px",
				borderStyle: "dashed",
				borderColor: "var(--bs-secondary)",
				padding: "1px",
			};
		}

		if (hasError) {
			tdStyle = {
				...tdStyle,
				borderColor: "var(--bs-danger)",
				borderWidth: "3px",
				padding: "1px",
			};
		}

		return tdStyle;
	};

	return (
		<td onClick={editShift} ref={attachRef} style={tdStyle()}>
			{isEditing ? (
				<input
					type="text"
					onBlur={handleOnBlur}
					autoFocus
					onKeyDown={handleKeyDown}
					onChange={handleChangeShift}
					onFocus={(e) => e.target.select()}
					defaultValue={formattedShiftTime}
				/>
			) : (
				<div style={{ height: "23px", letterSpacing: "-1px" }}>
					{formattedShiftTime}
				</div>
			)}
		</td>
	);
}

function formatShiftTime(shift) {
	const startTimeFormat =
		shift.startTime.substring(3, 5) === "00" ? "h a" : "h:mm a";
	const endTimeFormat =
		shift.endTime.substring(3, 5) === "00" ? "h a" : "h:mm a";
	return `${moment(shift.startTime, [moment.ISO_8601, "HH:mm:ss"]).format(
		startTimeFormat
	)} - ${moment(shift.endTime, [moment.ISO_8601, "HH:mm:ss"]).format(
		endTimeFormat
	)}`;
}

function isTimeRangeValid(timeRange) {
	try {
		parseTimeRange(timeRange);
		return true;
	} catch (e) {
		return false;
	}
}
 function parseTimeRange(shiftTimeRange) {
    const [startTimeString, endTimeString] = shiftTimeRange.split("-");
    if (!startTimeString || !endTimeString) {
      throw new Error("Please enter a valid time range separated by a dash (-).");
    }
    const startTime = moment(startTimeString, ["h:m a", "H:m"]);
    const endTime = moment(endTimeString, ["h:m a", "H:m"]);
    if (!startTime.isValid()) {
      throw new Error("Please enter a valid start time.");
    }
    if (!endTime.isValid()) {
      throw new Error("Please enter a valid end time.");
    }
    if (!endTime.isAfter(startTime)) {
      throw new Error("Please enter a valid time range.");
    }
    return {
      startTime: startTime.format("H:m"),
      endTime: endTime.format("H:m"),
    };
  }

export default Shift;
